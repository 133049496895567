@import "custom";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.noScroll {
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
}

@media (max-width: 575px) {
  .container {
    --bs-gutter-x: 25px;
  }

  .row {
    --bs-gutter-x: 50px;
  }
}

@font-face {
  font-family: 'Spartan';
  src: url('fonts/Spartan-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('fonts/Spartan-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('fonts/Spartan-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
