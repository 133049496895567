$breadcrumb-active-color: #006acc;
$breadcrumb-divider: none;
$breadcrumb-margin-bottom: 0;
$breadcrumb-item-padding-x: 0;

@import "~bootstrap/scss/bootstrap";

.breadcrumb-item, .breadcrumb-item > a {
  color: #006acc;
  font-family: Barlow, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
}
