@keyframes fadeInRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
  }
}

.fadeInRight {
  animation-name: fadeInRightAnimation;
}

@keyframes fadeInLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
  }
}

.fadeInLeft {
  animation-name: fadeInLeftAnimation;
}

@keyframes fadeInUpAnimation {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
  }
}

.fadeInUp {
  animation-name: fadeInUpAnimation;
}

@keyframes flyInLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  70% {
    opacity: 1;
  }
  100% {
  }
}

.flyIn {
  animation-name: flyInLeftAnimation;
}

@keyframes bounceInLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  70% {
    opacity: 1;
    transform: translateX(10px);
  }
  100% {
  }
}

.bounceIn {
  animation-name: bounceInLeftAnimation;
}
